import React, {useContext, useState, useEffect} from "react";
import {auth, db, googleAuthProvider} from "../firebase";
import { signInWithPopup, signInWithRedirect } from "firebase/auth";
import { getDoc, setDoc, doc} from "firebase/firestore";
import {roles} from "../util/roles/roles";

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({children}) {
    const [currentUser, setCurrentUser] = useState();
    const [userRole, setUserRole] = useState();
    const [loading, setLoading] = useState(true)

    async function authenticate(method) {
        try {
            let result;
            if (method === 'signInWithPopup') {
                result = await signInWithPopup(auth, googleAuthProvider);
            } else if (method === 'signInWithRedirect') {
                result = await signInWithRedirect(auth, googleAuthProvider);
            }
            return result   ;
        } catch (error) {
            console.log(error);
        }
    }

    const signup = () => authenticate('signInWithPopup');
    const login = () => authenticate('signInWithRedirect');

    function setRole(uid, displayName, email) {
        setUserRole({role: roles.USER});
        const docRef = doc(db, 'uRoles', uid);
        setDoc(docRef, {
            role: roles.USER,
            territories: ['All'],
            name: displayName,
            email: email
        }).then((docRef) => {
            console.log(docRef);
        }).catch((error) => {
            console.error("Error adding document: ", error);
        })
    }

    function logout() {
        return auth.signOut();
    }

    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email);
    }

    function updateEmail(email) {
        return currentUser.updateEmail(email);
    }

    function updatePassword(password) {
        return currentUser.updatePassword(password);
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
            setLoading(false);
        })

        return unsubscribe;
    }, [])

    useEffect(() => {
        if (currentUser) {
            const {uid, displayName, email} = currentUser;
            const docRef = doc(db, 'uRoles', uid);
            getDoc(docRef)
                .then((docSnap) => {
                    if (docSnap.exists()) {
                        setUserRole(docSnap.data())
                        console.log('User has a role')
                    } else {
                        setRole(uid, displayName, email)
                    }
                })
                .catch((error) => {
                    console.log("Error getting document:", error);
                });
        }
    }, [currentUser])

    const value = {
        currentUser,
        userRole,
        login,
        signup,
        logout,
        resetPassword,
        updateEmail,
        updatePassword
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}